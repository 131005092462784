export const DATA_LAYER_EVENTS = {
  VIEW_ITEM: 'view_item',
  VIEW_CART: 'view_cart',
  VIEW_ITEM_LIST: 'view_item_list',
  LOGIN: 'login',
  ADD_TO_CART: 'add_to_cart',
  REMOVE_FROM_CART: 'remove_from_cart',
  PURCHASE: 'purchase',
  PAGE_VIEW: 'page_view',
  SEARCH: 'search',
  BEGIN_CHECKOUT: 'begin_checkout',
  SIGN_UP: 'sign_up',
  ADD_SHIPPING_INFO: 'add_shipping_info',
} as const
